import { Space } from '@mantine/core';
import Header from '@/components/Header/Header';
import HorizontalAd from '@/components/Ads/HorizontalAd';
import Footer from '@/components/Footer/Footer';
import { useParams } from 'react-router-dom';
import SearchResults from '@/components/SearchResults/SearchResults';
import Search from '@/components/Search/Search';
import Featured from '@/components/Featured/Featured';
import { Helmet } from 'react-helmet';

export default () => {
    const { search: searchText } = useParams();
    return (
    <>
        <Helmet>
            <title>{`Search Results for ${searchText} | They Will Kill You`}</title>
        </Helmet>
        <Header />
        <Search />
        <HorizontalAd />
        <SearchResults />
        <HorizontalAd />
        <Featured title="It may interest you" shortUrl='highlights'/>
        <Space h="xl" />
        <Footer />
    </>
    );
};
