import { MantineProvider } from '@mantine/core';
import { QueryClient, QueryClientProvider } from 'react-query';

import { Router } from './Router';
import { theme } from './theme';

import '@fontsource/bebas-neue';
import '@fontsource/poppins';
import '@fontsource-variable/inter';

import '@mantine/core/styles.css';
import '@mantine/carousel/styles.css';

import './css/embla.css';

import './css/general.css';

const queryClient = new QueryClient();

export default () => (
  <QueryClientProvider client={queryClient}>
    <MantineProvider theme={theme}>
      <Router />
    </MantineProvider>
  </QueryClientProvider>
);
