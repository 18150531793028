import { Container } from '@mantine/core';
import { Link, useParams } from 'react-router-dom';
import classes from './IndexList.module.css';

const Cta = ( ) => {
    const alphabet = [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ'];
    const { letter: currentLetter } = useParams<{ letter: string }>();

    const currentIndex = alphabet.indexOf(currentLetter?.toUpperCase() || 'A');

    const prevLetter = alphabet[(currentIndex - 1 + alphabet.length) % alphabet.length];
    const nextLetter = alphabet[(currentIndex + 1) % alphabet.length];
    return (
        <Container size="xl" style={{ paddingTop: '48px', textAlign: 'center' }}>
            <h3>Search Killers By Name</h3>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                
                {currentLetter && (
                    <Link to={`/list/${prevLetter}`} className={classes.navigationLink}>
                        &#8592;
                    </Link>
                )}

                {alphabet.map((letter) => (
                    <Link
                        to={`/list/${letter}`}
                        key={letter}
                        className={`${classes.alphabetLink} ${currentLetter === letter ? classes.active : ''}`}
                    >
                        {letter}
                    </Link>
                ))}

                {currentLetter && (
                    <Link to={`/list/${nextLetter}`} className={classes.navigationLink}>
                        &#8594;
                    </Link>
                )}
            </div>
        </Container>
    );
};

export default Cta;
