import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import HomePage from './pages/Home.page';
import SearchPage from '@/pages/Search.page';
import ProfilePage from '@/pages/Profile.page';
import DatePage from '@/pages/Date.page';
import ListPage from '@/pages/List.page';
import NotFoundPage from '@/pages/Error.page';
import PrivacyPolicy from '@/pages/Privacy.page';
import TOSPage from './pages/TOS.page';
import EditBio from '@/pages/EditBio.page';
import EditBioThanks from '@/pages/EditBioThanks.page';

const router = createBrowserRouter([
    {
        path: '/',
        element: <HomePage />,
    },
    {
        path: '/list/:letter',
        element: <ListPage />,
    },
    {
        path: '/search/:search?',
        element: <SearchPage />,
    },
    {
        path: '/profile/:id',
        element: <ProfilePage />,
    },
    {
        path: '/edit-bio',
        element: <EditBio />,
    },
    {
        path: '/edit-bio-thanks',
        element: <EditBioThanks />,
    },
    {
        path: '/date/:date',
        element: <DatePage />,
    },
    {
        path: '/privacy-policy',
        element: <PrivacyPolicy />,
    },
    {
        path: '/terms-of-service',
        element: <TOSPage />,
    },
    {
        path: '*',
        element: <NotFoundPage />,
    },
    {
        path: '/products/*',
        element: null,
        loader: () => {
            window.location.href = 'https://shop.theywillkillyou.com' + window.location.pathname + window.location.search;
            return null;
        },
    },
    {
        path: '/collections/*',
        element: null,
        loader: () => {
            window.location.href = 'https://shop.theywillkillyou.com' + window.location.pathname + window.location.search;
            return null;
        },
    },
]);

export function Router() {
    return <RouterProvider router={router} />;
}
