import { useParams } from 'react-router-dom';
import { Space } from '@mantine/core';
import { useQuery } from 'react-query';
import Header from '@/components/Header/Header';
import HorizontalAd from '@/components/Ads/HorizontalAd';
import { Helmet } from 'react-helmet';
import Footer from '@/components/Footer/Footer';
import ListProfiles from '@/components/Profiles/ListProfiles';
import { getAll } from '@/queries';
import IndexList from '@/components/Profiles/IndexList';

export default () => {
    const { letter } = useParams<{ letter?: string }>();
    const { data, isLoading } = useQuery(['getAll', letter], () => getAll(letter));

    return (
        <>
            <Helmet>
                <title>{`Serial Killers that start with ${letter} | They Will Kill You`}</title>
            </Helmet>
            <Header />
            <HorizontalAd />
            <IndexList />
            <ListProfiles isLoading={isLoading} data={data} />
            <IndexList />
            <HorizontalAd />
            <Space h="xl" />
            <Footer />
        </>
    );
};
