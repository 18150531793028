import { Space } from '@mantine/core';
import Header from '@/components/Header/Header';
import HorizontalAd from '@/components/Ads/HorizontalAd';
import SignificantDatesModule from '@/components/SignificantDates/SignificantDatesModule';
import Quote from '@/components/Quote/Quote';
import Footer from '@/components/Footer/Footer';
import IndexList from '@/components/Profiles/IndexList';
import EmailListSignup from '@/components/Card/Email/EmailListSignup';
import { Helmet } from 'react-helmet';

export default () => (
    <>
        <Helmet>
            <title>They Will Kill You | The Internet's Largest Database of Murderers</title>
        </Helmet>
        <Header />
        <HorizontalAd />
        <SignificantDatesModule title="This Day In History" />
        <IndexList />
        <EmailListSignup />
        <Quote />
        <HorizontalAd />
        <Space h="xl" />
        <Space h="xl" />
        <Footer />
    </>
);
