import { Space, Container, Text, Button, Title } from '@mantine/core';
import Header from '@/components/Header/Header';
import HorizontalAd from '@/components/Ads/HorizontalAd';
import Featured from '@/components/Featured/Featured';
import Footer from '@/components/Footer/Footer';
import IndexList from '@/components/Profiles/IndexList';

export default function NotFoundPage() {
    return (
        <>
            <Header />
            <HorizontalAd />

            <Container>
                <Space h="xl" />
                <Title>404 - Not Found</Title>
                <Space h="md" />
                <Text>
                    It seems like the page you're looking for has disappeared into the shadows...
                </Text>
                <Space h="md" />
                <IndexList />
                <Space h="xl" />
            </Container>

            <Featured title="Featured Murderers" shortUrl='highlights'/>
            <HorizontalAd />
            <Space h="xl" />
            <Space h="xl" />
            <Footer />
        </>
    );
}
