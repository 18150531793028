import { Space } from '@mantine/core';
import Header from '@/components/Header/Header';
import PrivacyPolicy from '@/components/Static/PrivacyPolicy';
import Footer from '@/components/Footer/Footer';
import { Helmet } from 'react-helmet';

export default () => (
    <>
        <Helmet>
            <title>Privacy Policy | They Will Kill You</title>
        </Helmet>
        <Header />
        <PrivacyPolicy />
        <Space h="xl" />
        <Space h="xl" />
        <Footer />
    </>
);
