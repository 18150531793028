import { Space } from '@mantine/core';
import Header from '@/components/Header/Header';
import HorizontalAd from '@/components/Ads/HorizontalAd';
import SignificantDatesPage from '@/components/SignificantDates/SignificantDatesPage';
import Footer from '@/components/Footer/Footer';
import IndexList from '@/components/Profiles/IndexList';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

export default () => {
    const { date } = useParams<{ date?: string }>();

    const capitalizeFirstLetter = (string: string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const month = (date ?? '').replace(/[0-9]/g, '');
    const day = (date ?? '').replace(/[a-zA-Z]/g, '');
    const formattedMonth = capitalizeFirstLetter(month);
    const formattedDate = `${formattedMonth} ${day}`;

    return (
        <>
            <Helmet>
                <title>{`${formattedDate} This Day in Infamy | They Will Kill You`}</title>
            </Helmet>
            <Header />
            <HorizontalAd />
            <SignificantDatesPage title="In History" />
            <IndexList />
            <Space h="xl" />
            <Space h="xl" />
            <Footer />
        </>
    );
}
