import { Container } from '@mantine/core';
import './PrivacyPolicy.module.css';

const PrivacyPolicy = () => {
    return (
        <Container size="xl" mt="xl">
            <h1 className="privacy-title">Privacy Policy</h1>

            <section className="privacy-section">
                <h2 className="privacy-heading">1. Introduction</h2>
                <p className="privacy-text">
                    At <strong>They Will Kill You</strong>, we take your privacy seriously. This Privacy Policy explains how we collect, use, and protect your personal information. By using our site, you agree to the terms outlined in this policy.
                </p>
            </section>

            <section className="privacy-section">
                <h2 className="privacy-heading">2. Data We Collect</h2>
                <p className="privacy-text">
                    At <strong>They Will Kill You</strong>, we take your privacy seriously. This Privacy Policy explains how we collect, use, and protect your personal information. By using our site, you agree to the terms outlined in this policy.
                </p>
                <ul className="privacy-list">
                <li>
                    <strong>Email Information:</strong> We collect email addresses from users who opt-in to receive newsletters, marketing materials, or other updates.
                </li>
                <li>
                    <strong>Cookies and Tracking Information:</strong> We use cookies and tracking technologies provided by Google Analytics and Google Ads to gather information about your usage of the site, including browser type, device information, and interaction with ads.
                </li>
                </ul>
            </section>
            <section className="privacy-section">
                <h2 className="privacy-heading">3. Use of Collected Data</h2>
                <ul className="privacy-list">
                    <li>
                        <strong>Marketing and Communication:</strong> If you opt-in to our email list, we will use your email to send newsletters, promotional content, or other updates.
                    </li>
                    <li>
                        <strong>Analytics and Advertising:</strong> Data collected through Google Analytics and Google Ads helps us analyze user behavior and improve the site’s performance. This information may also be used for targeted advertising.
                    </li>
                </ul>
            </section>

            <section className="privacy-section">
                <h2 className="privacy-heading">4. Third-Party Services</h2>
                <p className="privacy-text">
                    We use several third-party services, including:
                </p>
                <ul className="privacy-list">
                    <li>Google Analytics for website traffic analysis</li>
                    <li>Google Ads for advertisement delivery</li>
                    <li>PayPal for donation processing</li>
                    <li>Shopify for any merchandise-related transactions</li>
                </ul>
                <p className="privacy-text">
                    Each of these services has its own privacy policy, and we encourage you to review them.
                </p>
            </section>

            <section className="privacy-section">
                <h2 className="privacy-heading">5. Cookies</h2>
                <p className="privacy-text">
                    Cookies are used to improve user experience and track site performance. You can control the use of cookies through your browser settings. However, disabling cookies may impact your ability to use certain features of the site.
                </p>
            </section>

            <section className="privacy-section">
                <h2 className="privacy-heading">6. Data Storage and Retention</h2>
                <p className="privacy-text">
                    We store collected data on secure cloud servers. Your personal data will be retained indefinitely unless you request its deletion. You may opt out of receiving emails at any time by clicking the unsubscribe link in any communication or by contacting us directly.
                </p>
            </section>

            <section className="privacy-section">
                <h2 className="privacy-heading">7. Data Security</h2>
                <p className="privacy-text">
                    We implement industry-standard security measures to protect your data from unauthorized access, disclosure, or alteration. However, no method of data transmission over the Internet or electronic storage is entirely secure, and we cannot guarantee absolute security.
                </p>
            </section>

            <section className="privacy-section">
                <h2 className="privacy-heading">8. User Rights</h2>
                <p className="privacy-text">
                    You have the right to access, modify, or request deletion of your personal data in accordance with applicable privacy laws, such as the GDPR or CCPA. If you wish to exercise these rights, please contact us directly.
                </p>
            </section>

            <section className="privacy-section">
                <h2 className="privacy-heading">9. Children's Privacy</h2>
                <p className="privacy-text">
                    Our site is not intended for children under the age of 13. We do not knowingly collect personal data from children under this age. If we become aware that we have inadvertently collected such data, we will delete it immediately.
                </p>
            </section>

            <section className="privacy-section">
                <h2 className="privacy-heading">10. Changes to the Privacy Policy</h2>
                <p className="privacy-text">
                    We reserve the right to update this Privacy Policy at any time. Changes will be posted on this page, and it is your responsibility to review the Privacy Policy periodically to stay informed of any updates.
                </p>
            </section>

            <section className="privacy-section">
                <h2 className="privacy-heading">11. Contact Us</h2>
                <p className="privacy-text">
                    If you have any questions about this Privacy Policy or wish to exercise your rights regarding your personal data, please contact us at:
                </p>
                <ul className="privacy-list">
                    <li>Email: <a href="mailto:boredbadgerllc@gmail.com">boredbadgerllc@gmail.com</a></li>
                </ul>
            </section>
        </Container>
    );
};

export default PrivacyPolicy;
